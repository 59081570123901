import { defineComponent, inject, onUnmounted } from 'vue'
import {
  CubeCamera,
  LinearMipmapLinearFilter,
  RGBFormat,
  WebGLCubeRenderTarget,
} from 'three'
import Object3D from './Object3D'
import { RendererInjectionKey } from './Renderer'

export default defineComponent({
  extends: Object3D,
  props: {
    cubeRTSize: { type: Number, default: 256 },
    cubeCameraNear: { type: Number, default: 0.1 },
    cubeCameraFar: { type: Number, default: 2000 },
    autoUpdate: Boolean,
  },
  setup(props) {
    const rendererC = inject(RendererInjectionKey)
    if (!rendererC || !rendererC.scene) {
      console.error('Missing Renderer / Scene')
      return
    }

    const renderer = rendererC.renderer, scene = rendererC.scene
    const cubeRT = new WebGLCubeRenderTarget(props.cubeRTSize, { format: RGBFormat, generateMipmaps: true, minFilter: LinearMipmapLinearFilter })
    const cubeCamera = new CubeCamera(props.cubeCameraNear, props.cubeCameraFar, cubeRT)
    const updateRT = () => { cubeCamera.update(renderer, scene) }

    if (props.autoUpdate) {
      rendererC.onBeforeRender(updateRT)
      onUnmounted(() => { rendererC.offBeforeRender(updateRT) })
    } else {
      rendererC.onMounted(updateRT)
    }

    return { cubeRT, cubeCamera }
  },
  render() {
    return []
  },
  __hmrId: 'CubeCamera',
})
